/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import 'primeng/resources/primeng.css';
@import './prime-ng/prime-ng';
@import '~jsoneditor/dist/jsoneditor.min.css';
@import './mixins';
@import './variables';
@import './modal';
@import './buttons';
@import './forms';
@import 'margins';
@import 'paddings';
@import './custom';

.global-placeholder-text{
    color: rgba(var(--fuse-text-secondary-rgb), var(--tw-text-opacity)) !important;
    font-style: italic;
    font-size: 13px;
}